<template>
  <div id="permissions-form-create">
    <div class="d-flex align-items-end justify-content-end">
      <b-button variant="primary"
        @click.prevent="_showModal()" v-if="permissions_visible.create_permissions">
        <feather-icon
          icon="PlusIcon"
          class="mr-50"/>
        <span class="align-middle">
          Nuevo Permiso
        </span>
      </b-button>
    </div>
    <b-modal
      centered
      hide-footer
      ref="modalCreate"
      title="Estas creando un nuevo Permiso">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="¿Cual es el nombre del nuevo permiso?" label-for="name">
              <b-form-input id="name"
                v-model="createForm.name"
                name="login-email"
                placeholder="Escribe el nombre aqui"
                :state="nameErrors.length > 0 ? false:null"
                @input="$v.createForm.name.$touch()"/>
                <small class="text-danger">{{ nameErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
            <b-form-group>
              <div>
                <b-overlay
                  :show="overlayForm"
                  opacity="0.5"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="_onHidden">
                  <b-button block
                    ref="button"
                    :disabled="overlayForm"
                    variant="primary"
                    @click.prevent="_createPermission">
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Guardar
                    </span>
                  </b-button>
                </b-overlay>
              </div>
            </b-form-group>
          </div>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import {required} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'PermissionsFormCreate',
    data(){
      return {
        permissions_visible:{
          create_permissions:false,
          show_permissions:false,
          edit_permissions:false,
          assign_permissions:false,
          delete_permissions:false,
        },
        overlayForm: false,
        createForm: {
          name: '',
        },
      }
    },
    validations: {
      createForm: {
        name: {
          required,
        },
      },
    },
    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.createForm.name.$dirty) return errors
        !this.$v.createForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
    },
    methods: {
      ...mapActions('permissions',['createPermissions']),
      async _createPermission () {
        this.$v.createForm.$touch()
        if (!this.$v.createForm.$invalid) {
          this.overlayForm = true
          await this.createPermissions(this.createForm)
          .then(res => {
            this._hideModal();
            this.createForm = {name: ''}
            this.$v.createForm.$reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Permiso registrado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al registrar el permiso',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      _showModal() {
        this.$refs['modalCreate'].show()
      },
      _hideModal() {
        this.$refs['modalCreate'].hide()
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>