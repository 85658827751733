<template>
  <div id="permissions-list" class="mt-2">
    <b-table
      hover
      striped
      show-empty
      responsive="xl"
      class="position-relative mb-0"
      :items="permissions"
      :fields="tableColumns"
      primary-key="id"
      empty-text="Datos no Disponibles">
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret
          :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon size="16"
              icon="MoreVerticalIcon"
              class="align-middle text-body"/>
          </template>
          <b-dropdown-item @click.prevent="_auditPermission(data.item)" v-if="permissions_visible.assign_permissions">
            <feather-icon icon="BookmarkIcon"/>
            <span class="align-middle ml-50">Auditoria</span>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="_editPermission(data.item)" v-if="permissions_visible.edit_permissions">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="_deletePermission(data.item)" v-if="permissions_visible.delete_permissions">
            <feather-icon icon="TrashIcon"  />
            <span class="align-middle ml-50">Eliminar</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <permissions-form-edit ref="modalPermissionEdit"></permissions-form-edit>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import PermissionsFormEdit from './PermissionsFormEdit'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'PermissionsList',
    props: ['permissions'],
    components: {PermissionsFormEdit},
    data () {
      return {
        permissions_visible:{
          create_permissions:false,
          show_permissions:false,
          edit_permissions:false,
          assign_permissions:false,
          delete_permissions:false,
        },
        selectPermission: [],
        tableColumns: [
          { key: 'id', sortable: false, label: 'ID'},
          { key: 'name', sortable: false, label: 'Nombre' },
          { key: 'actions', label: 'Acciones' },
        ]
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
    },
    methods: {
      ...mapActions('permissions',['deletePermissions']),
      _auditPermission(permission) {
        this.$router.push({ name: 'permissions-audits', params: { id: permission.id, audits: permission } })
      },
      _editPermission(permission) {
        this.$refs.modalPermissionEdit._showModal(permission)
      },
      _deletePermission(permission) {
        this.$swal({
          title: '¿Estás seguro que deseas eliminar este permiso?',
          text: 'La siguiente acción eliminará el permiso seleccionado.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this._confirmDelete(permission);
          }
        })
      },
      async _confirmDelete(permission) {
        await this.deletePermissions(permission)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Permiso eliminado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al eliminar el permiso',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      },
    }
  }
</script>
