<template>
  <div id="permissions">
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col cols="12">
          <permissions-form-create></permissions-form-create>
          <permissions-list :permissions="permissions"></permissions-list>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import {mapActions,mapState} from 'vuex';
  import PermissionsList from './components/PermissionsList'
  import PermissionsFormCreate from './components/PermissionsFormCreate'

  export default{
    name: 'PermissionsView',
    components: {PermissionsList,PermissionsFormCreate},
    data() {
      return {
      }
    },
    beforeMount() {
      this._fetchPermission()
    },
    computed: {
      ...mapState('permissions',['permissions']),
    },
    methods: {
      ...mapActions('permissions',['fetchPermissions']),
      async _fetchPermission () {
        await this.fetchPermissions()
      },
    }
  }
</script>